<template>
    <div class="main-container">
        <h1 class="title-solo">About us</h1>
        <div class="col-title">
            <img src="./../assets/logo-synthes3d.png" alt="synthes3d" />
            <p>
                Created in 2007, SYNTHES’3D is a French digital studio specialized in 3D Computer Graphics, Web development, virtual events, Realtime 3D and in contents for VR & AR Applications and Solutions.<br />
                In parallel of the historical core business, new investments & developments have been launched in Blockchain, NFT & Metaverse projects.<br />
                SYNTHES’3D is based in France (Aix-en-Provence & Angers) and in the USA and the team is composed of 15 talented designers for now.          
            </p>

        </div>

    </div>
</template>

<script>
export default {
    name : 'AboutUs',
    metaInfo: {
        title: 'About Us',
        meta: [
            { name: 'description', content: 'The next generation 3D virtual convention dedicated to the MedTech Industry.' },
        ]
    },
    data() {
        return {
            about : [
                {
                    logo : require('@/assets/about/logo-s3d.png'),
                    logoAlt : 'logo SYNTHES3D',
                    url : 'https://www.synthes3d.com/en/',
                    person : require('@/assets/about/vivien.jpg'),
                    name : 'Vivien Poujade',
                    text : 'A premiere engineer, Vivien Poujade is the Founder and President of SYNTHES’3D, a leader in 3D animations, motion graphics & Virtual Reality solutions for MedTech industries. In the past 13 years, he has helped hundreds of MedTech organizations, in the USA, in France & internationally, develop marketing, training & digital tools.'
                },
                // {
                //     logo : require('@/assets/about/OTW Logo.jpg'),
                //     logoAlt : 'logo OTW',
                //     url : 'https://ryortho.com/',
                //     person : require('@/assets/about/robin.jpg'),
                //     name : 'Robin Young',
                //     text : 'Founded by Robin Young, Orthopedics this week is the essential and most widely read publication in Orthopedics. With his 30 years of experience in the medical space, he helps his clients thrive with a range of business and scientific solut'
                // },
                {
                    logo : require('@/assets/about/logo-medtech.png'),
                    logoAlt : 'logo Med Tech',
                    url : 'https://medtechmomentum.com/',
                    person : require('@/assets/about/guillaume.jpg'),
                    name : 'Guillaume Viallaneix',
                    text : 'As MedTech Momentum’s Founder & President, Guillaume Viallaneix brings 22 years of experience as a MedTech industry executive and an unmatched expertise in healthcare marketing in the internet and social media era.'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .main-container {
        background-image: url('./../assets/bg-about.png');
        background-repeat: no-repeat;
        background-position: center left;
        min-height: auto;
    }

    .col-title {
        width: 1000px;
        max-width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom:120px;
    }

    .col-title img {
        width:250px;
        display: block;
        margin:40px auto 80px auto;
    }

    .col-title p {
        color:$text-color;
        text-align: justify;
        font-size:18px;
        line-height: 1.5em;
        width: 1000px;
        max-width: 90%;
        margin: 0 auto;
    }

    .col-title p strong {
        font-size:25px;
    }

    .col-title ul {
        color:$text-color;
        margin: 0 auto;
        padding:0;
    }

    .col-title ul li {
        list-style: none;
        font-size:18px;
        line-height: 1.5em;
    }
    
    .about-container {
        width: $box;
        margin: 0 auto;
        display: flex;
        max-width: 90%;
        justify-content: center;
        flex-wrap: wrap;
        margin-top:80px;
    }

    .about-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height:120px;
    }

    .about-item {
        width:33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-content {
        width:337px;
        border:1px solid $main-color;
        border-radius:10px;
        margin-top:20px;
        background: #fff;
    }

    .about-person {
        border-radius:10px;
    }

    .about-content h2 {
        text-align: center;
        font-size:22px;
        font-weight:700;
        text-align: center;
        margin:20px;
        padding: 0;
        text-transform: capitalize;
    }

    .about-content p {
        color:$secondary-color;
        text-align: justify;
        margin:0 20px 20px 20px;
        line-height: 1.5em;
        height:210px;
    }

    .col-title .text-left {
        text-align: left;
        margin:20px 0;
    }

    

    @media screen and (max-width:1200px) {
        .about-container {
            flex-direction: column;
            align-items: center;
        }

        .about-logo-container {
            margin-top:40px;
        }

        .about-item:nth-child(1) .about-logo-container {
            margin-top:0;
        }
    }

    @media screen and (max-width:768px) {
        .col-title {
            padding-bottom:40px;
        }
    }
</style>